import React from 'react';
import { Box } from '@mui/material';


import left from "../images/l.webp"
import right from "../images/pexels-photo-2084411.webp"

import LeftAndRight from '../components/LeftAndRight';
import Footer from '../components/footer';
const ST = () => {
    const data = [
        {
            img: left,
            h1 : "درابزين زجاجي" , 
            p: "من بين موديلات الدرابزين المطبقة مع خبرة سمافكو، هناك درابزين حامل زجاجي، درابزين من الفولاذ المقاوم للصدأ، درابزين ألومنيوم وموديلات درابزين مربعة. يضيف كل طراز من موديلات الدرابزين الأناقة والقيمة إلى المناطق التي يتم تطبيقه فيها بمظهره اللامع والحيوي بالإضافة إلى القوة والثقة والمتانة."
        }
    ]

    const data2 = [
        {
            img: right,
            h1 : "درابزين زجاجي" , 
            p: "نظام الدرابزين هي نظام مبتكر يتم تفضيله بشكل متكرر في التطبيقات الداخلية والخارجية للهندسة المعمارية الحالية وتضيف جودة إلى المساحات التي يستخدم فيها مع الجمالية التي تجلبها. غالبًا ما نرى تفضيل الدرابزين في التطبيقات الميدانية مثل درابزين الدرج، وإغلاق حماية الشرفة المفتوحة، والشرفات، ومداخل المباني، وحماية الميزانين. بينما يوفر الدرابزين حماية آمنة أو راحة في المنطقة التي يستخدم فيها، فإنه يضيف أيضًا جمالية إلى المساحة بمظهرها الجميل."
        }
    ]

    return (
        <Box className = "welcome st">
            <title> درابزين زجاج و استانلس </title>


            <LeftAndRight data={data} data2={data2} />

            <Footer/>

            
        </Box>
    );
}

export default ST;
